import { Routes, Route } from "react-router-dom";
import Home from "../views/Home";
import About from "../views/About";
import Founder from "../views/Founder";
import Certification from "../views/Certification";
import Initiative from "../views/Initiative";
import DirectMarketingPaln from "../views/DirectMarketingPlan";
import Advantages from "../views/Advantages";
import Incentives from "../views/Incentives";
import Downloads from "../views/Downloads";
import Contact from "../views/Contact";
import JoinAslTeam from "../views/JoinAslTeam";

function PageRoutes(){

    return (
  <Routes>
    <Route path="/" element={<Home/>} />
    <Route path="/about" element={<About/>} />
    <Route path="/founder" element={<Founder/>} />
    <Route path="/certification" element={<Certification/>} />
    <Route path="/initiative" element={<Initiative/>} />
    <Route path="/direct_marketing_plan" element={<DirectMarketingPaln/>} />
    <Route path="/advantages" element={<Advantages/>} />
    <Route path="/incentives" element={<Incentives/>} />
    <Route path="/downloads" element={<Downloads/>} />
    <Route path="/contact" element={<Contact/>} />
    <Route path="/join_asl_team" element={<JoinAslTeam/>} />
  </Routes>
    );
}

export default PageRoutes
