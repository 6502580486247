import Weblayout from "../layout/Weblayout";

function Home() {
  return (
    <Weblayout>
      <>
        {/* <div id="preloader">
        <div id="loader" className="loader">
            <div className="loader-container">
                <div className="loader-icon"><img src="assets/img/logo/preloader.png" alt="Preloader" /></div>
            </div>
        </div>
    </div> */}

        <a
          href="#top_bottom"
          className="scroll__top scroll-to-target"
          data-target="html">
          <i className="fas fa-angle-up"></i>
        </a>

        <main className="fix" id="top_bottom">
          <section className="banner-area banner-bg">
            <div className="container">
              <div className="row">
                <div className="col-lg-6">
                  <div className="banner-content" style={{ marginLeft: 50 }}>
                    <span
                      className="sub-title"
                      data-aos="fade-up"
                      data-aos-delay="0">
                      Aim is Secure life of employee
                    </span>
                    <h2
                      className="title text-white"
                      data-aos="fade-up"
                      data-aos-delay="200">
                      Let’s create a secure and thriving environment
                    </h2>
                    <p
                      className="text-white"
                      data-aos="fade-up"
                      data-aos-delay="400">
                      Fostering a workplace culture dedicated to securing the
                      life of every employee, where holistic well-being,
                      professional growth, and comprehensive benefits converge
                      to create a thriving and secure future for all.
                    </p>
                    <a
                      className="btn"
                      data-aos-delay="600"
                      data-aos="fade-up"
                      href="/about">
                      Read More
                    </a>
                  </div>
                  <div className="banner-shape">
                    <img
                      src="assets/img/banner/banner_shape01.png"
                      alt=""
                      className="rightToLeft"
                    />
                    <img
                      src="assets/img/banner/banner_shape02.png"
                      alt=""
                      className="ribbonRotate"
                    />
                  </div>
                </div>
              </div>
              <div className="banner-social">
                <h5 className="title text-white">Follow us</h5>
                <ul className="list-wrap">
                  <li>
                    <a href="javascript:void(0)">
                      <i className="fab fa-facebook-f text-white"></i>
                    </a>
                  </li>
                  <li>
                    <a href="javascript:void(0)">
                      <i className="fab fa-twitter text-white"></i>
                    </a>
                  </li>
                  <li>
                    <a href="javascript:void(0)">
                      <i className="fab fa-instagram text-white"></i>
                    </a>
                  </li>
                  <li>
                    <a href="javascript:void(0)">
                      <i className="fab fa-pinterest-p text-white"></i>
                    </a>
                  </li>
                  <li>
                    <a href="javascript:void(0)">
                      <i className="fab fa-linkedin-in text-white"></i>
                    </a>
                  </li>
                </ul>
              </div>
              <div className="banner-scroll">
                <a className="text-white" href="#about">
                  Scroll Down{" "}
                  <span>
                    <i className="fas fa-arrow-right text-white"></i>
                  </span>
                </a>
              </div>
            </div>
          </section>

          <section id="about" className="about-area pt-60 pb-60">
            <div className="container">
              <div className="row align-items-center">
                <div className="col-lg-6">
                  <div className="about-img-wrap">
                    <div className="mask-img-wrap">
                      <img src="assets/img/images/about_img01.jpg" alt="" />
                    </div>
                    <div className="shape">
                      <img src="assets/img/images/about_shape01.png" alt="" />
                    </div>
                    <div className="experience-year">
                      <div className="icon">
                        <i className="flaticon-trophy"></i>
                      </div>
                      <div className="content">
                        <h6 className="circle rotateme">
                          Years Of - Experience 25 -
                        </h6>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="about-content">
                    <div className="section-title mb-35 tg-heading-subheading animation-style3">
                      <span className="sub-title">
                        Secure Citizen, Secure India
                      </span>
                      <h2 className="title tg-element-title">
                        We Help Organizations To <br />
                        grow along with each employee secure life
                      </h2>
                    </div>
                    <div className="about-list">
                      <ul className="list-wrap">
                        <li>
                          <div className="icon">
                            <i className="flaticon-target"></i>
                          </div>
                          <div className="content">
                            <h4 className="title">Growing Business</h4>
                            <p>Secured life solutions for employees</p>
                          </div>
                        </li>
                        <li>
                          <div className="icon">
                            <i className="flaticon-profit"></i>
                          </div>
                          <div className="content">
                            <h4 className="title">Secured Employee</h4>
                            <p>Dedicated contribution for business growth</p>
                          </div>
                        </li>
                      </ul>
                    </div>
                    <p>
                      “Ensuring the well-being and safety of every employee,
                      fostering a secure and thriving work environment.,,
                    </p>
                    <div className="about-bottom">
                      <div className="author-wrap">
                        <div className="thumb">
                          <img src="assets/img/images/author_img.png" alt="" />
                        </div>
                        <div className="content">
                          <img src="assets/img/images/sign.png" alt="" />
                          <h4 className="title">
                            Puran Chand Arya <span>, Founder Chairman</span>
                          </h4>
                        </div>
                      </div>
                      <a className="btn btn-two" href="#">
                        Read More
                      </a>
                    </div>
                    <div className="about-shape-wrap">
                      <img src="assets/img/images/about_shape03.png" alt="" />
                      <img
                        src="assets/img/images/about_shape04.png"
                        alt=""
                        className="ribbonRotate"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="about-left-shape">
              <img src="assets/img/images/about_shape02.png" alt="" />
            </div>
          </section>

          <section
            className="services-area services-bg"
            data-background="assets/img/bg/services_bg.jpg">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-xl-6 col-lg-8">
                  <div className="section-title text-center mb-40 tg-heading-subheading animation-style3">
                    <span className="sub-title">WE OFFER SECURED LIFE</span>
                    <h2 className="title tg-element-title">
                      We offer HRM Services Solutions for business growth with
                      Secured Life options
                    </h2>
                  </div>
                </div>
              </div>
              <div className="services-item-wrap">
                <div className="row justify-content-center">
                  <div className="col-xl-3 col-lg-4 col-md-6 col-sm-8">
                    <div className="services-item shine-animate-item">
                      <div className="services-thumb">
                        <a className="shine-animate" href="#">
                          <img
                            src="assets/img/services/services_img01.jpg"
                            alt=""
                          />
                        </a>
                      </div>
                      <div className="services-content">
                        <div className="icon">
                          <i className="flaticon-profit"></i>
                        </div>
                        <h4 className="title">
                          <a href="#">SCOPE OF SERVICES AND SUPPORT</a>
                        </h4>
                        <p>
                          Morem ipsum dolor sittemet consectetur adipiscing.
                        </p>
                        <a className="btn" href="#">
                          Read More
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-3 col-lg-4 col-md-6 col-sm-8">
                    <div className="services-item shine-animate-item">
                      <div className="services-thumb">
                        <a className="shine-animate" href="#">
                          <img
                            src="assets/img/services/services_img02.jpg"
                            alt=""
                          />
                        </a>
                      </div>
                      <div className="services-content">
                        <div className="icon">
                          <i className="flaticon-investment-1"></i>
                        </div>
                        <h4 className="title">
                          <a href="#">ADVANTAGES ABHHYAM SECURE LIFE (ASL)</a>
                        </h4>
                        <p>
                          Morem ipsum dolor sittemet consectetur adipiscing.
                        </p>
                        <a className="btn" href="#">
                          Read More
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-3 col-lg-4 col-md-6 col-sm-8">
                    <div className="services-item shine-animate-item">
                      <div className="services-thumb">
                        <a className="shine-animate" href="#">
                          <img
                            src="assets/img/services/services_img03.jpg"
                            alt=""
                          />
                        </a>
                      </div>
                      <div className="services-content">
                        <div className="icon">
                          <i className="flaticon-pie-chart"></i>
                        </div>
                        <h4 className="title">
                          <a href="#">PENSIONARY BENEFITS FOR EMPLOYEES</a>
                        </h4>
                        <p>
                          Morem ipsum dolor sittemet consectetur adipiscing.
                        </p>
                        <a className="btn" href="#">
                          Read More
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-3 col-lg-4 col-md-6 col-sm-8">
                    <div className="services-item shine-animate-item">
                      <div className="services-thumb">
                        <a className="shine-animate" href="#">
                          <img
                            src="assets/img/services/services_img04.jpg"
                            alt=""
                          />
                        </a>
                      </div>
                      <div className="services-content">
                        <div className="icon">
                          <i className="flaticon-light-bulb"></i>
                        </div>
                        <h4 className="title">
                          <a href="#">
                            INITIATIVE: “CITIZEN SECURE – COUNTRY SECURE”
                          </a>
                        </h4>
                        <p>
                          Morem ipsum dolor sittemet consectetur adipiscing.
                        </p>
                        <a className="btn" href="#">
                          Read More
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className="choose-area">
            <div className="container">
              <div className="row align-items-center">
                <div className="col-lg-6 order-0 order-lg-2">
                  <div className="choose-img-wrap">
                    <img src="assets/img/images/choose_img01.jpg" alt="" />
                    <img
                      src="assets/img/images/choose_img02.jpg"
                      alt=""
                      data-parallax='{"x" : 50 }'
                    />
                    <img
                      src="assets/img/images/choose_img_shape.png"
                      alt=""
                      className="alltuchtopdown"
                    />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="choose-content">
                    <div className="section-title white-title mb-30 tg-heading-subheading animation-style3">
                      <span className="sub-title">
                        Citizen Secure-Country secure
                      </span>
                      <h2 className="title tg-element-title">
                        Employee welfare and care <br /> is a cornerstone of
                        organizational success.
                      </h2>
                    </div>
                    <p>
                      Employee welfare and care is a cornerstone of
                      organizational success. When organizations invest in the
                      well-being of their employees, they create a workplace
                      where employees are satisfied, motivated, and committed to
                      achieving the organization's goals. Employee welfare is
                      not just an ethical obligation; it is a strategic
                      advantage that positively impacts an organization's
                      performance, culture, and reputation. As organizations
                      continue to evolve, they must recognize that the heart of
                      their success lies in nurturing the well-being of their
                      employees. By investing in employees' well-being and
                      providing the necessary support, organizations can create
                      a positive and productive work environment, improve job
                      satisfaction, and ultimately achieve be􀆩er business
                      results. Employee care is a win-win, benefiting both
                      employees and the organization. If employee happy, the
                      company is happy, if employee secure, the company secure
                      or we can say If citizen is secured, the country will be
                      secured.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="choose-shape-wrap">
              <img
                src="assets/img/images/choose_shape01.png"
                alt=""
                data-aos="fade-right"
                data-aos-delay="400"
              />
              <img
                src="assets/img/images/choose_shape02.png"
                alt=""
                data-aos="fade-left"
                data-aos-delay="400"
              />
            </div>
          </section>

          <section className="counter-area">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-xl-3 col-lg-4 col-sm-6">
                  <div className="counter-item">
                    <div className="icon">
                      <i className="flaticon-trophy"></i>
                    </div>
                    <div className="content">
                      <h2 className="count">
                        <span className="odometer" data-count="45"></span>
                      </h2>
                      <p>EMC – (Entity Member Company Registered)</p>
                    </div>
                  </div>
                </div>
                <div className="col-xl-3 col-lg-4 col-sm-6">
                  <div className="counter-item">
                    <div className="icon">
                      <i className="flaticon-happy"></i>
                    </div>
                    <div className="content">
                      <h2 className="count">
                        <span className="odometer" data-count="92"></span>
                      </h2>
                      <p>ERM (Entity Rep Member)</p>
                    </div>
                  </div>
                </div>
                <div className="col-xl-3 col-lg-4 col-sm-6">
                  <div className="counter-item">
                    <div className="icon">
                      <i className="flaticon-china"></i>
                    </div>
                    <div className="content">
                      <h2 className="count">
                        <span className="odometer" data-count="19"></span>
                      </h2>
                      <p>District wise presence</p>
                    </div>
                  </div>
                </div>
                <div className="col-xl-3 col-lg-4 col-sm-6">
                  <div className="counter-item">
                    <div className="icon">
                      <i className="flaticon-time"></i>
                    </div>
                    <div className="content">
                      <h2 className="count">
                        <span className="odometer" data-count="25"></span>
                      </h2>
                      <p>Active ASL Account</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="counter-shape-wrap">
              <img
                src="assets/img/images/counter_shape01.png"
                alt=""
                data-aos="fade-right"
                data-aos-delay="400"
              />
              <img
                src="assets/img/images/counter_shape02.png"
                alt=""
                data-parallax='{"x" : 100 , "y" : -100 }'
              />
              <img
                src="assets/img/images/counter_shape03.png"
                alt=""
                data-aos="fade-left"
                data-aos-delay="400"
              />
            </div>
          </section>

          <section className="project-area">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-xl-6 col-lg-7">
                  <div className="section-title text-center mb-50 tg-heading-subheading animation-style3">
                    <span className="sub-title">OUR PROJECTS</span>
                    <h2 className="title tg-element-title">
                      Let’s Discover All Our Clients Recent Project
                    </h2>
                  </div>
                </div>
              </div>
            </div>
            <div className="project-item-wrap">
              <div className="container custom-container-two">
                <div className="row">
                  <div className="col-xl-3 col-md-6">
                    <div className="project-item">
                      <div className="project-thumb">
                        <a href="#">
                          <img
                            src="assets/img/project/project_img01.jpg"
                            alt=""
                          />
                        </a>
                      </div>
                      <div className="project-content">
                        <div className="left-side-content">
                          <h4 className="title">
                            <a href="#">Business Consulting</a>
                          </h4>
                          <span>Business Strategy</span>
                        </div>
                        <div className="link-arrow">
                          <a href="#">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 18 15"
                              fill="none">
                              <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M17.6293 3.27957C17.7117 2.80341 17.4427 2.34763 17.0096 2.17812C16.9477 2.15385 16.8824 2.13552 16.8144 2.12376L6.96081 0.419152C6.41654 0.325049 5.89911 0.689856 5.80491 1.23411C5.71079 1.77829 6.07564 2.29578 6.61982 2.38993L14.0946 3.68295L1.36574 12.6573C0.914365 12.9756 0.806424 13.5995 1.12467 14.0509C1.44292 14.5022 2.06682 14.6102 2.51819 14.2919L15.247 5.31753L13.954 12.7923C13.8598 13.3365 14.2247 13.854 14.7689 13.9482C15.3131 14.0422 15.8305 13.6774 15.9248 13.1332L17.6293 3.27957Z"
                                fill="currentcolor"
                              />
                              <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M17.6293 3.27957C17.7117 2.80341 17.4427 2.34763 17.0096 2.17812C16.9477 2.15385 16.8824 2.13552 16.8144 2.12376L6.96081 0.419152C6.41654 0.325049 5.89911 0.689856 5.80491 1.23411C5.71079 1.77829 6.07564 2.29578 6.61982 2.38993L14.0946 3.68295L1.36574 12.6573C0.914365 12.9756 0.806424 13.5995 1.12467 14.0509C1.44292 14.5022 2.06682 14.6102 2.51819 14.2919L15.247 5.31753L13.954 12.7923C13.8598 13.3365 14.2247 13.854 14.7689 13.9482C15.3131 14.0422 15.8305 13.6774 15.9248 13.1332L17.6293 3.27957Z"
                                fill="currentcolor"
                              />
                            </svg>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-3 col-md-6">
                    <div className="project-item">
                      <div className="project-thumb">
                        <a href="#">
                          <img
                            src="assets/img/project/project_img02.jpg"
                            alt=""
                          />
                        </a>
                      </div>
                      <div className="project-content">
                        <div className="left-side-content">
                          <h4 className="title">
                            <a href="#">Digital Agency</a>
                          </h4>
                          <span>Business Services</span>
                        </div>
                        <div className="link-arrow">
                          <a href="#">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 18 15"
                              fill="none">
                              <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M17.6293 3.27957C17.7117 2.80341 17.4427 2.34763 17.0096 2.17812C16.9477 2.15385 16.8824 2.13552 16.8144 2.12376L6.96081 0.419152C6.41654 0.325049 5.89911 0.689856 5.80491 1.23411C5.71079 1.77829 6.07564 2.29578 6.61982 2.38993L14.0946 3.68295L1.36574 12.6573C0.914365 12.9756 0.806424 13.5995 1.12467 14.0509C1.44292 14.5022 2.06682 14.6102 2.51819 14.2919L15.247 5.31753L13.954 12.7923C13.8598 13.3365 14.2247 13.854 14.7689 13.9482C15.3131 14.0422 15.8305 13.6774 15.9248 13.1332L17.6293 3.27957Z"
                                fill="currentcolor"
                              />
                              <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M17.6293 3.27957C17.7117 2.80341 17.4427 2.34763 17.0096 2.17812C16.9477 2.15385 16.8824 2.13552 16.8144 2.12376L6.96081 0.419152C6.41654 0.325049 5.89911 0.689856 5.80491 1.23411C5.71079 1.77829 6.07564 2.29578 6.61982 2.38993L14.0946 3.68295L1.36574 12.6573C0.914365 12.9756 0.806424 13.5995 1.12467 14.0509C1.44292 14.5022 2.06682 14.6102 2.51819 14.2919L15.247 5.31753L13.954 12.7923C13.8598 13.3365 14.2247 13.854 14.7689 13.9482C15.3131 14.0422 15.8305 13.6774 15.9248 13.1332L17.6293 3.27957Z"
                                fill="currentcolor"
                              />
                            </svg>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-3 col-md-6">
                    <div className="project-item">
                      <div className="project-thumb">
                        <a href="#">
                          <img
                            src="assets/img/project/project_img03.jpg"
                            alt=""
                          />
                        </a>
                      </div>
                      <div className="project-content">
                        <div className="left-side-content">
                          <h4 className="title">
                            <a href="#">Inventory Management</a>
                          </h4>
                          <span>Inventory Tracking</span>
                        </div>
                        <div className="link-arrow">
                          <a href="#">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 18 15"
                              fill="none">
                              <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M17.6293 3.27957C17.7117 2.80341 17.4427 2.34763 17.0096 2.17812C16.9477 2.15385 16.8824 2.13552 16.8144 2.12376L6.96081 0.419152C6.41654 0.325049 5.89911 0.689856 5.80491 1.23411C5.71079 1.77829 6.07564 2.29578 6.61982 2.38993L14.0946 3.68295L1.36574 12.6573C0.914365 12.9756 0.806424 13.5995 1.12467 14.0509C1.44292 14.5022 2.06682 14.6102 2.51819 14.2919L15.247 5.31753L13.954 12.7923C13.8598 13.3365 14.2247 13.854 14.7689 13.9482C15.3131 14.0422 15.8305 13.6774 15.9248 13.1332L17.6293 3.27957Z"
                                fill="currentcolor"
                              />
                              <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M17.6293 3.27957C17.7117 2.80341 17.4427 2.34763 17.0096 2.17812C16.9477 2.15385 16.8824 2.13552 16.8144 2.12376L6.96081 0.419152C6.41654 0.325049 5.89911 0.689856 5.80491 1.23411C5.71079 1.77829 6.07564 2.29578 6.61982 2.38993L14.0946 3.68295L1.36574 12.6573C0.914365 12.9756 0.806424 13.5995 1.12467 14.0509C1.44292 14.5022 2.06682 14.6102 2.51819 14.2919L15.247 5.31753L13.954 12.7923C13.8598 13.3365 14.2247 13.854 14.7689 13.9482C15.3131 14.0422 15.8305 13.6774 15.9248 13.1332L17.6293 3.27957Z"
                                fill="currentcolor"
                              />
                            </svg>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-3 col-md-6">
                    <div className="project-item">
                      <div className="project-thumb">
                        <a href="#">
                          <img
                            src="assets/img/project/project_img04.jpg"
                            alt=""
                          />
                        </a>
                      </div>
                      <div className="project-content">
                        <div className="left-side-content">
                          <h4 className="title">
                            <a href="#">Business Accounting</a>
                          </h4>
                          <span>Financing Management</span>
                        </div>
                        <div className="link-arrow">
                          <a href="#">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 18 15"
                              fill="none">
                              <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M17.6293 3.27957C17.7117 2.80341 17.4427 2.34763 17.0096 2.17812C16.9477 2.15385 16.8824 2.13552 16.8144 2.12376L6.96081 0.419152C6.41654 0.325049 5.89911 0.689856 5.80491 1.23411C5.71079 1.77829 6.07564 2.29578 6.61982 2.38993L14.0946 3.68295L1.36574 12.6573C0.914365 12.9756 0.806424 13.5995 1.12467 14.0509C1.44292 14.5022 2.06682 14.6102 2.51819 14.2919L15.247 5.31753L13.954 12.7923C13.8598 13.3365 14.2247 13.854 14.7689 13.9482C15.3131 14.0422 15.8305 13.6774 15.9248 13.1332L17.6293 3.27957Z"
                                fill="currentcolor"
                              />
                              <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M17.6293 3.27957C17.7117 2.80341 17.4427 2.34763 17.0096 2.17812C16.9477 2.15385 16.8824 2.13552 16.8144 2.12376L6.96081 0.419152C6.41654 0.325049 5.89911 0.689856 5.80491 1.23411C5.71079 1.77829 6.07564 2.29578 6.61982 2.38993L14.0946 3.68295L1.36574 12.6573C0.914365 12.9756 0.806424 13.5995 1.12467 14.0509C1.44292 14.5022 2.06682 14.6102 2.51819 14.2919L15.247 5.31753L13.954 12.7923C13.8598 13.3365 14.2247 13.854 14.7689 13.9482C15.3131 14.0422 15.8305 13.6774 15.9248 13.1332L17.6293 3.27957Z"
                                fill="currentcolor"
                              />
                            </svg>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row justify-content-center">
                  <div className="col-12">
                    <div className="project-content-bottom">
                      <p>
                        We successfully cope with tasks of varying complexity,{" "}
                        <br /> provide long-term guarantees and regularly
                      </p>
                      <a className="btn" href="#">
                        See All Projects
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="project-shape-wrap">
              <img
                src="assets/img/project/project_shape01.png"
                alt=""
                className="alltuchtopdown"
              />
              <img
                src="assets/img/project/project_shape02.png"
                alt=""
                className="rotateme"
              />
            </div>
          </section>

          <section
            className="request-area request-bg"
            data-background="assets/img/bg/request_bg.jpg">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-lg-8">
                  <div className="request-content text-center tg-heading-subheading animation-style3">
                    <h2 className="title tg-element-title">
                      Offering The Best Experience Of Business Consulting
                      Services
                    </h2>
                    <div className="content-bottom">
                      <a href="tel:0123456789" className="btn">
                        Request a Free Call
                      </a>
                      <div className="content-right">
                        <div className="icon">
                          <i className="flaticon-phone-call"></i>
                        </div>
                        <div className="content">
                          <span>Toll Free Call</span>
                          <a href="tel:0123456789">+ 88 ( 9600 ) 6002</a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="request-shape">
              <img
                src="assets/img/images/request_shape01.png"
                alt=""
                data-aos="fade-right"
                data-aos-delay="400"
              />
              <img
                src="assets/img/images/request_shape02.png"
                alt=""
                data-aos="fade-left"
                data-aos-delay="400"
              />
            </div>
          </section>

          <section className="team-area pt-120 pb-90">
            <div className="container">
              <div className="row align-items-center">
                <div className="col-xl-7 col-lg-6">
                  <div className="section-title mb-40 tg-heading-subheading animation-style3">
                    <span className="sub-title">ASL Team</span>
                    <h2 className="title tg-element-title">
                      Team Behind the Abhhyam Secured Life
                    </h2>
                  </div>
                </div>
                <div className="col-xl-5 col-lg-6"></div>
              </div>
              <div className="team-item-wrap">
                <div className="row justify-content-center">
                  <div className="col-xl-3 col-lg-4 col-md-6 col-sm-8">
                    <div className="team-item">
                      <div className="team-thumb">
                        <img src="assets/img/team/team_img01.jpg" alt="" />
                        <div className="team-social">
                          <div className="social-toggle-icon">
                            <i className="fas fa-share-alt"></i>
                          </div>
                          <ul className="list-wrap">
                            <li>
                              <a href="javascript:void(0)">
                                <i className="fab fa-facebook-f"></i>
                              </a>
                            </li>
                            <li>
                              <a href="javascript:void(0)">
                                <i className="fab fa-twitter"></i>
                              </a>
                            </li>
                            <li>
                              <a href="javascript:void(0)">
                                <i className="fab fa-instagram"></i>
                              </a>
                            </li>
                            <li>
                              <a href="javascript:void(0)">
                                <i className="fab fa-pinterest-p"></i>
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="team-content">
                        <h4 className="title">
                          <a href="/team-details">
                            Puran Chand Arya, (Retd) CRPF
                          </a>
                        </h4>
                        <span>Founder Chairman</span>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-3 col-lg-4 col-md-6 col-sm-8">
                    <div className="team-item">
                      <div className="team-thumb">
                        <img src="assets/img/team/team_img02.jpg" alt="" />
                        <div className="team-social">
                          <div className="social-toggle-icon">
                            <i className="fas fa-share-alt"></i>
                          </div>
                          <ul className="list-wrap">
                            <li>
                              <a href="javascript:void(0)">
                                <i className="fab fa-facebook-f"></i>
                              </a>
                            </li>
                            <li>
                              <a href="javascript:void(0)">
                                <i className="fab fa-twitter"></i>
                              </a>
                            </li>
                            <li>
                              <a href="javascript:void(0)">
                                <i className="fab fa-instagram"></i>
                              </a>
                            </li>
                            <li>
                              <a href="javascript:void(0)">
                                <i className="fab fa-pinterest-p"></i>
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="team-content">
                        <h4 className="title">
                          <a href="#">Er. Navin Kumar Sah</a>
                        </h4>
                        <span>Managing Director</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className="consulting-area">
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <div className="consulting-inner-wrap shine-animate-item">
                    <div className="consulting-content">
                      <div className="content-left">
                        <h2 className="title">40+</h2>
                        <span>
                          Consulting <br /> farm
                        </span>
                      </div>
                      <div className="content-right">
                        <h2 className="title">
                          Trusted, Happy and satisfied Entities
                        </h2>
                        <p>
                          There is a huge need to take various initiative to
                          develop the employment opportunities with secure life
                          of employees.
                        </p>
                      </div>
                    </div>
                    <div className="consulting-img shine-animate">
                      <img src="assets/img/images/consulting_img.jpg" alt="" />
                    </div>
                    <div className="consulting-shape">
                      <img
                        src="assets/img/images/consulting_shape.png"
                        alt=""
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className="testimonial-area">
            <div className="container">
              <div className="row align-items-center justify-content-center">
                <div className="col-lg-6 order-0 order-lg-2">
                  <div className="swiper-container testimonial-active">
                    <div className="swiper-wrapper">
                      <div className="swiper-slide">
                        <div className="testimonial-item">
                          <div className="testimonial-info">
                            <h4 className="title">Puran Chand Arya</h4>
                            <span>Founder Chairman, ASL</span>
                          </div>

                          <div className="testimonial-content">
                            <p>
                              "Our ASL HRM Services provide a holistic approach,
                              to set a new standard for service that seamlessly
                              integrates the well-being of both employees and
                              employers, ultimately fostering a harmonious and
                              prosperous workplace."
                            </p>
                            <div className="icon">
                              <i className="fas fa-quote-right"></i>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-8">
                  <div className="testimonial-img-wrap">
                    <img src="assets/img/images/testimonial_img.png" alt="" />
                    <div className="img-shape">
                      <img
                        src="assets/img/images/testimonial_shape01.png"
                        alt=""
                      />
                      <img
                        src="assets/img/images/testimonial_shape02.png"
                        alt=""
                        className="alltuchtopdown"
                      />
                      <img
                        src="assets/img/images/testimonial_shape03.png"
                        alt=""
                        data-parallax='{"y" : 80 }'
                      />
                      <img
                        src="assets/img/images/testimonial_shape04.png"
                        alt=""
                        className="rightToLeft"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="testimonial-shape-wrap">
              <img
                src="assets/img/images/testimonial_shape05.png"
                alt=""
                data-aos="fade-up"
                data-aos-delay="400"
              />
              <img
                src="assets/img/images/testimonial_shape06.png"
                alt=""
                data-aos="fade-left"
                data-aos-delay="400"
              />
            </div>
          </section>

          <section
            className="blog-post-area blog-post-bg"
            data-background="assets/img/bg/blog_post_bg.jpg">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-xl-6">
                  <div className="section-title text-center mb-40 tg-heading-subheading animation-style3">
                    <span className="sub-title">OUR BLOG UPDATE</span>
                    <h2 className="title tg-element-title">
                      Featured News And Insights
                    </h2>
                  </div>
                </div>
              </div>
              <div className="row justify-content-center">
                <div className="col-xl-4 col-lg-6 col-md-10">
                  <div className="blog-post-item shine-animate-item">
                    <div className="blog-post-thumb">
                      <a className="shine-animate" href="#">
                        <img src="assets/img/blog/blog_post01.jpg" alt="" />
                      </a>
                      <a className="post-tag" href="#">
                        Business
                      </a>
                    </div>
                    <div className="blog-post-content">
                      <h2 className="title">
                        <a href="#">
                          Marketing your are business downturn now a days
                        </a>
                      </h2>
                      <div className="blog-avatar">
                        <div className="avatar-thumb">
                          <img src="assets/img/blog/blog_avatar01.png" alt="" />
                        </div>
                        <div className="avatar-content">
                          <p>
                            By <a href="#">Doman Smith</a>
                          </p>
                        </div>
                      </div>
                      <div className="blog-post-meta">
                        <ul className="list-wrap">
                          <li>
                            <a className="btn" href="#">
                              Read More
                            </a>
                          </li>
                          <li>
                            <i className="fas fa-calendar-alt"></i>Oct 21, 2024
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-4 col-lg-6 col-md-10">
                  <div className="blog-post-item shine-animate-item">
                    <div className="blog-post-thumb">
                      <a className="shine-animate" href="#">
                        <img src="assets/img/blog/blog_post02.jpg" alt="" />
                      </a>
                      <a className="post-tag" href="#">
                        Audit
                      </a>
                    </div>
                    <div className="blog-post-content">
                      <h2 className="title">
                        <a href="#">
                          Marketing your are business downturn now a days
                        </a>
                      </h2>
                      <div className="blog-avatar">
                        <div className="avatar-thumb">
                          <img src="assets/img/blog/blog_avatar01.png" alt="" />
                        </div>
                        <div className="avatar-content">
                          <p>
                            By <a href="#">Doman Smith</a>
                          </p>
                        </div>
                      </div>
                      <div className="blog-post-meta">
                        <ul className="list-wrap">
                          <li>
                            <a className="btn" href="#">
                              Read More
                            </a>
                          </li>
                          <li>
                            <i className="fas fa-calendar-alt"></i>Oct 21, 2024
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-4 col-lg-6 col-md-10">
                  <div className="blog-post-item shine-animate-item">
                    <div className="blog-post-thumb">
                      <a className="shine-animate" href="#">
                        <img src="assets/img/blog/blog_post03.jpg" alt="" />
                      </a>
                      <a className="post-tag" href="#">
                        Investment
                      </a>
                    </div>
                    <div className="blog-post-content">
                      <h2 className="title">
                        <a href="#">
                          Marketing your are business downturn now a days
                        </a>
                      </h2>
                      <div className="blog-avatar">
                        <div className="avatar-thumb">
                          <img src="assets/img/blog/blog_avatar01.png" alt="" />
                        </div>
                        <div className="avatar-content">
                          <p>
                            By <a href="#">Doman Smith</a>
                          </p>
                        </div>
                      </div>
                      <div className="blog-post-meta">
                        <ul className="list-wrap">
                          <li>
                            <a className="btn" href="#">
                              Read More
                            </a>
                          </li>
                          <li>
                            <i className="fas fa-calendar-alt"></i>Oct 21, 2024
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="blog-shape-wrap">
              <img
                src="assets/img/images/blog_shape01.png"
                alt=""
                data-aos="fade-right"
                data-aos-delay="400"
              />
              <img
                src="assets/img/images/blog_shape02.png"
                alt=""
                data-aos="fade-left"
                data-aos-delay="400"
              />
            </div>
          </section>

          <section className="call-back-area">
            <div className="container">
              <div className="row">
                <div className="col-lg-6">
                  <div className="call-back-content">
                    <div className="section-title white-title mb-10 tg-heading-subheading animation-style3">
                      <h2 className="title tg-element-title">
                        Request A Call Back
                      </h2>
                    </div>
                    <p>
                      Ever find yourself staring at your computer screen a good
                      consulting slogan to come to mind? Oftentimes.
                    </p>
                    <div className="shape">
                      <img
                        src="assets/img/images/call_back_shape.png"
                        alt=""
                        data-aos="fade-right"
                        data-aos-delay="400"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="call-back-form">
                    <form action="#">
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-grp">
                            <input type="text" placeholder="Name *" />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-grp">
                            <input type="email" placeholder="E-mail *" />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-grp">
                            <input type="number" placeholder="Phone *" />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <button type="submit" className="btn">
                            Send Now
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </main>
      </>
    </Weblayout>
  );
}

export default Home;
