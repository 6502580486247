import { useEffect, useState } from "react";
import Weblayout from "../layout/Weblayout";
import { Link } from "react-router-dom";
import axios from "axios";
import toast, { Toaster } from "react-hot-toast";

function JoinAslTeam() {
  const [employer_name, set_employer_name] = useState("");
  const [no_of_employee, set_no_of_employee] = useState("");
  const [email, set_email] = useState("");
  const [mobile, set_mobile] = useState("");
  const [state, set_state] = useState("");
  const [district, set_district] = useState("");
  const [pincode, set_pincode] = useState("");
  const [state_data, set_state_data] = useState([]);
  const [district_data, set_district_data] = useState([]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      let body = JSON.stringify({
        employer_name,
        no_of_employee,
        email,
        mobile,
        state,
        district,
        pincode,
      });
      const response = await axios.post(
        "https://abhhyamsecure.com/api/api/v1/website/submit-enquiry",
        body,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      console.log(response);
      document.getElementById("contact-form").reset();
      toast.success(response.data.msg);
      set_employer_name("");
      set_no_of_employee("");
      set_email("");
      set_mobile("");
      set_state("");
      set_district("");
      set_pincode("");
    } catch (error) {
      console.error(error);
      toast.error("There was an error submitting the form. Please try again.");
    }
  };

  const getStateList = async () => {
    try {
      const response = await axios.get(
        "https://abhhyamsecure.com/api/api/v1/website/state"
      );
      set_state_data(response.data.data);
    } catch (error) {
      console.error(error);
    }
  };

  const getDistrictList = async (state_id) => {
    try {
      let body = JSON.stringify({
        state_id,
      });
      const response = await axios.post(
        "https://abhhyamsecure.com/api/api/v1/website/district",
        body,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      set_district_data(response.data.data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getStateList();
  }, []);

  useEffect(() => {
    getDistrictList(state);
  }, [state]);

  return (
    <Weblayout>
      <>
        <Toaster position="top-right" reverseOrder={false} />
        <a
          href="#top_bottom"
          className="scroll__top scroll-to-target"
          data-target="html">
          <i className="fas fa-angle-up"></i>
        </a>

        <main className="fix" id="top_bottom">
          <section className="breadcrumb__area breadcrumb__bg about_banner_bg">
            <div className="container">
              <div className="row">
                <div className="col-lg-6">
                  <div className="breadcrumb__content">
                    <h2 className="title">Registration</h2>
                    <nav aria-label="breadcrumb">
                      <ol className="breadcrumb">
                        <li className="breadcrumb-item">
                          <Link to="/">Home</Link>
                        </li>
                        <li
                          className="breadcrumb-item active"
                          aria-current="page">
                          Registration
                        </li>
                      </ol>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
            <div className="breadcrumb__shape">
              <img src="assets/img/images/breadcrumb_shape01.png" alt="" />
              <img
                src="assets/img/images/breadcrumb_shape02.png"
                alt=""
                className="rightToLeft"
              />
              <img src="assets/img/images/breadcrumb_shape03.png" alt="" />
              <img src="assets/img/images/breadcrumb_shape04.png" alt="" />
              <img
                src="assets/img/images/breadcrumb_shape05.png"
                alt=""
                className="alltuchtopdown"
              />
            </div>
          </section>

          <section className="contact__area">
            <div className="container">
              <div className="row align-items-center">
                <div className="col-lg-8 offset-lg-2">
                  <div className="contact__form-wrap">
                    <h2 className="title mb-3">Registration </h2>

                    <form
                      id="contact-form"
                      onSubmit={handleSubmit}
                      method="POST">
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-grp">
                            <input
                              type="text"
                              name="employer_name"
                              value={employer_name}
                              onChange={(e) =>
                                set_employer_name(e.target.value)
                              }
                              placeholder="Employer name"
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-grp">
                            <input
                              type="text"
                              name="no_of_employee"
                              value={no_of_employee}
                              onChange={(e) =>
                                set_no_of_employee(e.target.value)
                              }
                              placeholder="No. Of Employees"
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-grp">
                            <input
                              type="email"
                              name="email"
                              value={email}
                              onChange={(e) => set_email(e.target.value)}
                              placeholder="Email"
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-grp">
                            <input
                              type="number"
                              name="phone"
                              value={mobile}
                              onChange={(e) => set_mobile(e.target.value)}
                              placeholder="Phone"
                            />
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="form-grp">
                            <select
                              value={state}
                              onChange={(e) => set_state(e.target.value)}>
                              <option value="" disabled>
                                Select State
                              </option>
                              {state_data.map((data) => {
                                return (
                                  <option key={data.id} value={data.id}>
                                    {data.name}
                                  </option>
                                );
                              })}
                            </select>
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="form-grp">
                            <select
                              value={district}
                              onChange={(e) => set_district(e.target.value)}>
                              <option value="" disabled>
                                Select District
                              </option>
                              {district_data.map((data) => {
                                return (
                                  <option key={data.id} value={data.id}>
                                    {data.name}
                                  </option>
                                );
                              })}
                            </select>
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="form-grp">
                            <input
                              type="text"
                              name="pincode"
                              value={pincode}
                              onChange={(e) => set_pincode(e.target.value)}
                              placeholder="Pincode"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="form-grp checkbox-grp">
                        <input type="checkbox" name="checkbox" id="checkbox" />
                        <label htmlFor="checkbox">Terms & Conditions</label>
                      </div>
                      <button type="submit" className="btn">
                        Submit
                      </button>
                    </form>
                    <p className="ajax-response mb-0"></p>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className="call-back-area">
            <div className="container">
              <div className="row">
                <div className="col-lg-6">
                  <div className="call-back-content">
                    <div className="section-title white-title mb-10">
                      <h2 className="title">Request A Call Back</h2>
                    </div>
                    <p>
                      Ever find yourself staring at your computer screen a good
                      consulting slogan to come to mind? Oftentimes.
                    </p>
                    <div className="shape">
                      <img
                        src="assets/img/images/call_back_shape.png"
                        alt=""
                        data-aos="fade-right"
                        data-aos-delay="400"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="call-back-form">
                    <form action="#">
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-grp">
                            <input type="text" placeholder="Name *" />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-grp">
                            <input type="email" placeholder="E-mail *" />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-grp">
                            <input type="number" placeholder="Phone *" />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <button type="submit" className="btn">
                            Send Now
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </main>
      </>
    </Weblayout>
  );
}

export default JoinAslTeam;
