import { Link } from "react-router-dom";

function Footer() {
  return (
    <footer>
      <div className="footer-area">
        <div className="footer-top">
          <div className="container">
            <div className="row">
              <div className="col-xl-3 col-lg-4 col-md-6">
                <div className="footer-widget">
                  <div className="fw-logo mb-25">
                    <Link to="#">
                      <img src="assets/img/logo/logo.png" alt="" />
                    </Link>
                  </div>
                  <div className="footer-content">
                    <p>
                      ASL (Abhhyam Secure Life) is an innovative and holistic
                      approach to employee welfare and organizational stability.
                    </p>
                    <div className="footer-social">
                      <ul className="list-wrap">
                        <li>
                          <Link to="#">
                            <i className="fab fa-facebook-f"></i>
                          </Link>
                        </li>
                        <li>
                          <Link to="#">
                            <i className="fab fa-twitter"></i>
                          </Link>
                        </li>
                        <li>
                          <Link to="#">
                            <i className="fab fa-instagram"></i>
                          </Link>
                        </li>
                        <li>
                          <Link to="#">
                            <i className="fab fa-pinterest-p"></i>
                          </Link>
                        </li>
                        <li>
                          <Link to="#">
                            <i className="fab fa-youtube"></i>
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6">
                <div className="footer-widget">
                  <h4 className="fw-title">Information</h4>
                  <div className="footer-info-list">
                    <ul className="list-wrap">
                      <li>
                        <div className="icon">
                          <i className="flaticon-phone-call"></i>
                        </div>
                        <div className="content">
                          <Link to="tel:9971713967">+91-9971713967</Link>
                        </div>
                      </li>
                      <li>
                        <div className="icon">
                          <i className="flaticon-envelope"></i>
                        </div>
                        <div className="content">
                          <Link to="mailto:abhhyamsecure@gmail.com">
                            abhhyamsecure@gmail.com
                          </Link>
                        </div>
                      </li>
                      <li>
                        <div className="icon">
                          <i className="flaticon-pin"></i>
                        </div>
                        <div className="content">
                          <p>C-7/82, Sector-8, Rohini, New Delhi-110085</p>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6">
                <div className="footer-widget">
                  <h4 className="fw-title">Top Links</h4>
                  <div className="footer-link-list">
                    <ul className="list-wrap">
                      <li>
                        <Link to="#">About ASL</Link>
                      </li>
                      <li>
                        <Link to="#">Disclaimer</Link>
                      </li>
                      <li>
                        <Link to="#">Privacy Policy</Link>
                      </li>
                      <li>
                        <Link to="#">Terms of Use</Link>
                      </li>
                      <li>
                        <Link to="#">Terms and conditions</Link>
                      </li>
                      <li>
                        <Link to="#">Grievance </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-lg-4 col-md-6">
                <div className="footer-widget">
                  <h4 className="fw-title">Instagram Posts</h4>
                  <div className="footer-instagram">
                    <ul className="list-wrap">
                      <li>
                        <Link href="#">
                          <img
                            src="assets/img/images/footer_insta01.jpg"
                            alt=""
                          />
                        </Link>
                      </li>
                      <li>
                        <Link href="#">
                          <img
                            src="assets/img/images/footer_insta02.jpg"
                            alt=""
                          />
                        </Link>
                      </li>
                      <li>
                        <Link href="#">
                          <img
                            src="assets/img/images/footer_insta03.jpg"
                            alt=""
                          />
                        </Link>
                      </li>
                      <li>
                        <Link href="#">
                          <img
                            src="assets/img/images/footer_insta04.jpg"
                            alt=""
                          />
                        </Link>
                      </li>
                      <li>
                        <Link href="#">
                          <img
                            src="assets/img/images/footer_insta05.jpg"
                            alt=""
                          />
                        </Link>
                      </li>
                      <li>
                        <Link href="#">
                          <img
                            src="assets/img/images/footer_insta06.jpg"
                            alt=""
                          />
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-bottom">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-7 order-0 order-lg-2">
                <div className="footer-newsletter">
                  <h4 className="title">Newsletter SignUp!</h4>
                  <form action="#">
                    <input type="text" placeholder="e-mail Type . . ." />
                    <button className="btn btn-two" type="submit">
                      Subscribe
                    </button>
                  </form>
                </div>
              </div>
              <div className="col-lg-5">
                <div className="copyright-text">
                  <p>
                    Copyright © <Link to="#">Abhhyamsecure.com</Link> | All
                    Right Reserved
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-shape">
          <img
            src="assets/img/images/footer_shape01.png"
            alt=""
            data-aos="fade-right"
            data-aos-delay="400"
          />
          <img
            src="assets/img/images/footer_shape02.png"
            alt=""
            data-aos="fade-left"
            data-aos-delay="400"
          />
          <img
            src="assets/img/images/footer_shape03.png"
            alt=""
            data-parallax='{"x" : 100 , "y" : -100 }'
          />
        </div>
      </div>
    </footer>
  );
}

export default Footer;
