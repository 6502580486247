import Weblayout from "../layout/Weblayout";
import { Link } from "react-router-dom";

function Incentives(){
    return (
    <Weblayout>
        <>    
        <a href="#top_bottom" className="scroll__top scroll-to-target" data-target="html"><i className="fas fa-angle-up"></i></a>
      
      <main className="fix" id="top_bottom">
      <section className="breadcrumb__area breadcrumb__bg about_banner_bg">
            <div class="container">
                <div class="row">
                    <div class="col-lg-6">
                        <div class="breadcrumb__content">
                            <h2 class="title">Incentives</h2>
                            <nav aria-label="breadcrumb">
                                <ol class="breadcrumb">
                                    <li class="breadcrumb-item"><a href='/'>Home</a></li>
                                    <li class="breadcrumb-item active" aria-current="page">Incentives</li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
            <div class="breadcrumb__shape">
                <img src="assets/img/images/breadcrumb_shape01.png" alt="" />
                <img src="assets/img/images/breadcrumb_shape02.png" alt="" class="rightToLeft" />
                <img src="assets/img/images/breadcrumb_shape03.png" alt="" />
                <img src="assets/img/images/breadcrumb_shape04.png" alt="" />
                <img src="assets/img/images/breadcrumb_shape05.png" alt="" class="alltuchtopdown" />
            </div>
        </section>
        
        <section class="services__details-area">
            <div class="container">
                <div class="services__details-wrap">
                    <div class="row">
                        <div class="col-70 order-0 order-lg-2">
                            <div class="services__details-top">
                                <div class="row gutter-24 align-items-center">
                                    <div class="col-49">
                                        <div class="services__details-thumb services__details-thumb-two">
                                            <img src="assets/img/services/services_details02.jpg" alt="" />
                                        </div>
                                    </div>
                                    <div class="col-51">
                                        <div class="services__details-top-content">
                                            <h2 class="title">Investment Business Planning</h2>
                                            <p>when an unknown printer took a galley of type and scrambled it to make a type specimen bookhas survived not only five centuries.but also the leap into electronic typesetting, remaining.</p>
                                            <div class="about__list-box about__list-box-three">
                                                <ul class="list-wrap">
                                                    <li><i class="flaticon-arrow-button"></i>Business Growth</li>
                                                    <li><i class="flaticon-arrow-button"></i>100% Secure</li>
                                                    <li><i class="flaticon-arrow-button"></i>Research</li>
                                                    <li><i class="flaticon-arrow-button"></i>100% Secure</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="services__details-content">
                                <p>eed a little help from our friends from time to time. Although we offer the one-stop convenience of annery integrated range of legal, financial services under one roof, there are occasions when our clients areaneed specia- list advice beyond the scope of our own expertise. That’s why we’ve developed close working relationships with a number of strategic partner.</p>
                                <div class="services__details-inner-two">
                                    <div class="row gutter-24 align-items-center">
                                        <div class="col-48 order-0 order-md-2">
                                            <div class="services__details-inner-img">
                                                <img src="assets/img/services/services_details_inner02.jpg" alt="" />
                                            </div>
                                        </div>
                                        <div class="col-52">
                                            <div class="services__details-inner-content-two">
                                                <h4 class="title">Let’s Enroll Our Business Growth Management</h4>
                                                <p>eed a little help from our friends from time to time. Although we offer the one-stop convenience of annery integrated range of legal, financial services under one roof, there are occasions when our clients areaneed specia- list advice beyond the scope of our own expertise. That’s why we’ve developed.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <h2 class="title-two">3 Simple Steps to Process</h2>
                                <p>when an unknown printer took a galley of type and scrambled it to make a type specimen bookhas survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchan galley of type and scrambled it to make a type specimen book.</p>
                                <div class="services__details-list-two">
                                    <div class="row gutter-24">
                                        <div class="col-md-4">
                                            <div class="services__details-list-box-two">
                                                <div class="icon">
                                                    <i class="flaticon-report"></i>
                                                </div>
                                                <div class="content">
                                                    <h4 class="title">Business Ratings</h4>
                                                    <p>We successfully cope year withtks arying mplexity</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="services__details-list-box-two">
                                                <div class="icon">
                                                    <i class="flaticon-email"></i>
                                                </div>
                                                <div class="content">
                                                    <h4 class="title">Digital Marketing</h4>
                                                    <p>We successfully cope year withtks arying mplexity</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="services__details-list-box-two">
                                                <div class="icon">
                                                    <i class="flaticon-life-insurance"></i>
                                                </div>
                                                <div class="content">
                                                    <h4 class="title">Extend Coverage</h4>
                                                    <p>We successfully cope year withtks arying mplexity</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <p class="last-info">when an unknown printer took a galley of type and scrambled it to make a type specimen bookhas survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchan galley of type and scrambled it to make a type specimen book.</p>
                            </div>
                        </div>
                        <div class="col-30">
                            <aside class="services__sidebar">
                                <div class="sidebar__widget sidebar__widget-three">
                                    <div class="sidebar__cat-list-two sidebar__cat-list-three">
                                        <ul class="list-wrap">
                                            <li><Link to='#'>Investment Planning <i class="flaticon-arrow-button"></i></Link></li>
                                            <li><Link to='#'>Strategic marketing <i class="flaticon-arrow-button"></i></Link></li>
                                            <li><Link to='#'>Insights & analytics <i class="flaticon-arrow-button"></i></Link></li>
                                            <li><Link to='#'>Business consulting <i class="flaticon-arrow-button"></i></Link></li>
                                            <li><Link to='#'>Financial Advisory <i class="flaticon-arrow-button"></i></Link></li>
                                            <li><Link to='#'>Market Research <i class="flaticon-arrow-button"></i></Link></li>
                                        </ul>
                                    </div>
                                </div>
                                <div class="sidebar__widget sidebar__widget-two">
                                    <div class="sidebar__contact sidebar__contact-two" data-background="assets/img/services/sidebar_contact_bg.jpg">
                                        <h2 class="title">If You Need Any Help Contact With Us</h2>
                                        <Link to="tel:0123456789" class="btn"><i class="flaticon-phone-call"></i>+91 705 2101 786</Link>
                                    </div>
                                </div>
                                <div class="sidebar__widget sidebar__widget-three">
                                    <h4 class="sidebar__widget-title">Brochure</h4>
                                    <div class="sidebar__brochure sidebar__brochure-two">
                                        <p>when an unknown printer took ga lley offer typey anddey.</p>
                                        <Link to="assets/img/services/services_details01.jpg" target="_blank" download><i class="far fa-file-pdf"></i>PDF. Download</Link>
                                        <Link to="assets/img/services/services_details01.jpg" target="_blank" download><i class="far fa-file-alt"></i>DOC. Download</Link>
                                    </div>
                                </div>
                            </aside>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        
        <div class="brand__area-six">
            <div class="container">
                <div class="swiper-container brand-active">
                    <div class="swiper-wrapper">
                        <div class="swiper-slide">
                            <div class="brand-item">
                                <img src="assets/img/brand/brand_img01.png" alt="" />
                            </div>
                        </div>
                        <div class="swiper-slide">
                            <div class="brand-item">
                                <img src="assets/img/brand/brand_img02.png" alt="" />
                            </div>
                        </div>
                        <div class="swiper-slide">
                            <div class="brand-item">
                                <img src="assets/img/brand/brand_img03.png" alt="" />
                            </div>
                        </div>
                        <div class="swiper-slide">
                            <div class="brand-item">
                                <img src="assets/img/brand/brand_img04.png" alt="" />
                            </div>
                        </div>
                        <div class="swiper-slide">
                            <div class="brand-item">
                                <img src="assets/img/brand/brand_img05.png" alt="" />
                            </div>
                        </div>
                        <div class="swiper-slide">
                            <div class="brand-item">
                                <img src="assets/img/brand/brand_img06.png" alt="" />
                            </div>
                        </div>
                        <div class="swiper-slide">
                            <div class="brand-item">
                                <img src="assets/img/brand/brand_img03.png" alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
    </main>
    </>
   </Weblayout>  
   );
    }

export default Incentives    