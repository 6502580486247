import Weblayout from "../layout/Weblayout";
import { Link } from "react-router-dom";

function DirectMarketingPaln(){
    return (
    <Weblayout>
    <>        
    <a href="#top_bottom" className="scroll__top scroll-to-target" data-target="html"><i className="fas fa-angle-up"></i></a>
      
      <main className="fix" id="top_bottom">
        
        <section class="breadcrumb__area breadcrumb__bg about_banner_bg">
            <div class="container">
                <div class="row">
                    <div class="col-lg-6">
                        <div class="breadcrumb__content">
                            <h2 class="title">Direct Marketing Plan</h2>
                            <nav aria-label="breadcrumb">
                                <ol class="breadcrumb">
                                    <li class="breadcrumb-item"><Link to='/'>Home</Link></li>
                                    <li class="breadcrumb-item active" aria-current="page">Direct Marketing Plan</li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
            <div class="breadcrumb__shape">
                <img src="assets/img/images/breadcrumb_shape01.png" alt="" />
                <img src="assets/img/images/breadcrumb_shape02.png" alt="" class="rightToLeft" />
                <img src="assets/img/images/breadcrumb_shape03.png" alt="" />
                <img src="assets/img/images/breadcrumb_shape04.png" alt="" />
                <img src="assets/img/images/breadcrumb_shape05.png" alt="" class="alltuchtopdown" />
            </div>
        </section>
        
        <section class="services__details-area">
            <div class="container">
                <div class="services__details-wrap">
                    <div class="row">
                        <div class="col-70 order-0 order-lg-2">
                            <div class="services__details-thumb">
                                <img src="assets/img/services/services_details05.jpg" alt="" />
                            </div>
                            <div class="services__details-content services__details-content-two">
                                <h2 class="title">We help our clients identify their they area issues develop solutions and take action</h2>
                                <p>eed a little help from our friends from time to time. Although we offer the one-stop convenience of annery integrated range of legal, financial services under one roof, there are occasions when our clients areaneed specia- list advice beyond the scope of our own expertise. </p>
                                <div class="services__details-inner-six">
                                    <div class="row gutter-24 align-items-center">
                                        <div class="col-lg-7 col-md-6">
                                            <div class="services__details-inner-content-three">
                                                <h3 class="title">Our Business Goal</h3>
                                                <p>when an unknown printer took are galley type der one roof, thereand scrambled itter to make a type specimen bookhas a not only five centurie </p>
                                            </div>
                                        </div>
                                        <div class="col-lg-5 col-md-6">
                                            <div class="services__details-inner-graph">
       <img src="assets/img/services/services_details_graph01.jpg" alt="" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <h2 class="title-two">Our Corporate Business Planning</h2>
                                <p>when an unknown printer took a galley of type and scrambled it to make a type specimen bookhas survived not only five centuries.but also the leap into electronic typesetting, remaining.</p>
                                <div class="services__details-inner-img-wrap">
                                    <div class="row">
                                        <div class="col-md-6">
       <img src="assets/img/services/services_details_inner05.jpg" alt="" />
                                        </div>
                                        <div class="col-md-6">
       <img src="assets/img/services/services_details_inner06.jpg" alt="" />
                                        </div>
                                    </div>
                                </div>
                                <p class="last-info">when an unknown printer took a galley of type and scrambled it to make a type specimen bookhas survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchan galley of type and scrambled it to make a type specimen book.when an unknown printer took a galley of type and scrambled it to make a type specimen bookhas survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchan galle.</p>
                            </div>
                        </div>
                        <div class="col-30">
                            <aside class="services__sidebar">
                                <div class="sidebar__widget">
                                    <div class="sidebar__cat-list-two">
                                        <ul class="list-wrap">
                                            <li><Link to='/services-details'>Investment Planning <i class="flaticon-arrow-button"></i></Link></li>
                                            <li><Link to='/services-details'>Strategic marketing <i class="flaticon-arrow-button"></i></Link></li>
                                            <li><Link to='/services-details'>Insights & analytics <i class="flaticon-arrow-button"></i></Link></li>
                                            <li><Link to='/services-details'>Business consulting <i class="flaticon-arrow-button"></i></Link></li>
                                            <li><Link to='/services-details'>Financial Advisory <i class="flaticon-arrow-button"></i></Link></li>
                                            <li><Link to='/services-details'>Market Research <i class="flaticon-arrow-button"></i></Link></li>
                                        </ul>
                                    </div>
                                </div>
                                <div class="sidebar__widget sidebar__widget-two">
                                    <div class="sidebar__contact sidebar__contact-two sidebar_head_contact" >
                                        <h2 class="title">If You Need Any Help Contact With Us</h2>
                                        <Link to="tel:0123456789" class="btn"><i class="flaticon-phone-call"></i>+91 705 2101 786</Link>
                                    </div>
                                </div>
                                <div class="sidebar__widget">
                                    <h4 class="sidebar__widget-title">Brochure</h4>
                                    <div class="sidebar__brochure">
                                        <p>when an unknown printer took ga lley offer typey anddey.</p>
                                        <Link to="assets/img/services/services_details01.jpg" target="_blank" download><i class="far fa-file-pdf"></i>PDF. Download</Link>
                                        <Link to="assets/img/services/services_details01.jpg" target="_blank" download><i class="far fa-file-alt"></i>DOC. Download</Link>
                                    </div>
                                </div>
                            </aside>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        
        <section class="call-back-area">
            <div class="container">
                <div class="row">
                    <div class="col-lg-6">
                        <div class="call-back-content">
                            <div class="section-title white-title mb-10">
                                <h2 class="title">Request A Call Back</h2>
                            </div>
                            <p>Ever find yourself staring at your computer screen a good consulting slogan to come to mind? Oftentimes.</p>
                            <div class="shape">
                                <img src="assets/img/images/call_back_shape.png" alt="" data-aos="fade-right" data-aos-delay="400" />
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="call-back-form">
                            <form action="#">
                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="form-grp">
                                            <input type="text" placeholder="Name *" />
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="form-grp">
                                            <input type="email" placeholder="E-mail *" />
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="form-grp">
                                            <input type="number" placeholder="Phone *" />
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <button type="submit" class="btn">Send Now</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        
    </main>

    </>
    </Weblayout>
    );
    }

    export default DirectMarketingPaln

    
    