import Weblayout from "../layout/Weblayout";
import { Link } from "react-router-dom";

function Downloads() {
  return (
    <Weblayout>
      <>
        <a
          href="#top_bottom"
          className="scroll__top scroll-to-target"
          data-target="html">
          <i className="fas fa-angle-up"></i>
        </a>

        <main className="fix" id="top_bottom">
          <section className="breadcrumb__area breadcrumb__bg about_banner_bg">
            <div class="container">
              <div class="row">
                <div class="col-lg-6">
                  <div class="breadcrumb__content">
                    <h2 class="title">Downloads</h2>
                    <nav aria-label="breadcrumb">
                      <ol class="breadcrumb">
                        <li class="breadcrumb-item">
                          <Link to="/">Home</Link>
                        </li>
                        <li class="breadcrumb-item active" aria-current="page">
                          Downloads
                        </li>
                      </ol>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
            <div class="breadcrumb__shape">
              <img src="assets/img/images/breadcrumb_shape01.png" alt="" />
              <img
                src="assets/img/images/breadcrumb_shape02.png"
                alt=""
                class="rightToLeft"
              />
              <img src="assets/img/images/breadcrumb_shape03.png" alt="" />
              <img src="assets/img/images/breadcrumb_shape04.png" alt="" />
              <img
                src="assets/img/images/breadcrumb_shape05.png"
                alt=""
                class="alltuchtopdown"
              />
            </div>
          </section>

          <section class="project__details-area">
            <div class="container">
              <div class="project__details-wrap">
                <div class="row">
                  <div class="col-12">
                    <div class="project__details-top">
                      <div class="row">
                        <div class="col-70">
                          <div class="project__details-thumb">
                            <img
                              src="assets/img/project/project_details01.jpg"
                              alt=""
                            />
                          </div>
                        </div>
                        <div class="col-30">
                          <div class="project__details-info">
                            <h4 class="title">Project Details</h4>
                            <ul class="list-wrap">
                              <li>
                                <span>Category:</span>Business Consulting
                              </li>
                              <li>
                                <span>Client:</span>Mack Straing
                              </li>
                              <li>
                                <span>Location:</span>USA
                              </li>
                              <li>
                                <span>Value:</span>$50K
                              </li>
                              <li>
                                <span>Website:</span>www.apexa.com
                              </li>
                              <li>
                                <span>Share:</span>
                                <ul class="list-wrap project-social">
                                  <li>
                                    <Link to="#">
                                      <i class="fab fa-facebook-f"></i>
                                    </Link>
                                  </li>
                                  <li>
                                    <Link to="#">
                                      <i class="fab fa-twitter"></i>
                                    </Link>
                                  </li>
                                  <li>
                                    <Link to="#">
                                      <i class="fab fa-instagram"></i>
                                    </Link>
                                  </li>
                                  <li>
                                    <Link to="#">
                                      <i class="fab fa-pinterest-p"></i>
                                    </Link>
                                  </li>
                                </ul>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="project__details-content">
                      <h2 class="title">Company Values & The Relationship</h2>
                      <p>
                        eed a little help from our friends from time to time.
                        Although we offer the one-stop convenience of annery
                        integrated range of legal, financial services under one
                        roof, there are occasions when our clients areaneed
                        specia- list advice beyond the scope of our own
                        expertise.when an unknown printer took a galley of type
                        and scrambled it to make a type specimen bookhas
                        survived not only.
                      </p>
                      <p>
                        when an unknown printer took a galley of type and
                        scrambled it to make a type specimen bookhas survived
                        not only five centuries, but also the leap into
                        electronic typesetting, remaining essentially unchan
                        galley of type and scrambled it to make a type specimen
                        book.when an unknown.
                      </p>
                      <div class="project__details-inner">
                        <div class="row align-items-center">
                          <div class="col-lg-6 order-0 order-lg-2">
                            <div class="project__details-inner-img">
                              <img
                                src="assets/img/project/project_details02.jpg"
                                alt=""
                              />
                            </div>
                          </div>
                          <div class="col-lg-6">
                            <div class="project__details-inner-content">
                              <h2 class="title">
                                Our Corporate Business Planning
                              </h2>
                              <p>
                                when an unknown printer took a galley of type
                                and scrambled it to make a type specimen bookhas
                                survived not only five centuries.but also the
                                leap into electronic typesetting, remaining.
                              </p>
                              <div class="content-inner">
                                <div class="graph-img">
                                  <img
                                    src="assets/img/project/graph.jpg"
                                    alt=""
                                  />
                                </div>
                                <div class="about__list-box">
                                  <ul class="list-wrap">
                                    <li>
                                      <i class="flaticon-arrow-button"></i>
                                      Medicare Advantage Plans
                                    </li>
                                    <li>
                                      <i class="flaticon-arrow-button"></i>
                                      Analysis & Research
                                    </li>
                                    <li>
                                      <i class="flaticon-arrow-button"></i>100%
                                      Secure Money Back
                                    </li>
                                    <li>
                                      <i class="flaticon-arrow-button"></i>100%
                                      Money Growth
                                    </li>
                                  </ul>
                                </div>
                              </div>
                              <p class="last-info">
                                when an unknown printer took a galley of type
                                and scrambled it to make specimen bookhas
                                survived not only five centuries, but also the
                                leap into electronic typesetting, remaining
                                essentially unchan galley of type and scrambled
                                specimen book.when an unknown.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section class="call-back-area">
            <div class="container">
              <div class="row">
                <div class="col-lg-6">
                  <div class="call-back-content">
                    <div class="section-title white-title mb-10">
                      <h2 class="title">Request A Call Back</h2>
                    </div>
                    <p>
                      Ever find yourself staring at your computer screen a good
                      consulting slogan to come to mind? Oftentimes.
                    </p>
                    <div class="shape">
                      <img
                        src="assets/img/images/call_back_shape.png"
                        alt=""
                        data-aos="fade-right"
                        data-aos-delay="400"
                      />
                    </div>
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="call-back-form">
                    <form action="#">
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-grp">
                            <input type="text" placeholder="Name *" />
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-grp">
                            <input type="email" placeholder="E-mail *" />
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-grp">
                            <input type="number" placeholder="Phone *" />
                          </div>
                        </div>
                        <div class="col-md-6">
                          <button type="submit" class="btn">
                            Send Now
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </main>
      </>
    </Weblayout>
  );
}

export default Downloads;
